.errorDiv {
    margin-Top: 20%;
    margin-Bottom: 20%;
}

.errorPara {
    text-Align: center;
}

.boldSeparator {
    height: 2px;
}
