.homeDiv {
    margin-Top: 18%;
    margin-Bottom: 19%;
}

.homeFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
}
