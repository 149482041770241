/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    font-family: "UniversLTPro-67BoldCond";
    color: #000000;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    border: 1px solid #27d434;
    border-radius: 20px;
    background-color: #27d434;
    padding: 8px 32px;
    text-transform: uppercase;
}

    .btn-primary:hover {
        background-color: #3bea1f;
        border: 1px solid #3bea1f;
        color: #000000;
        transition: 0.7s;
    }

    .btn-primary:focus {
        outline-color: transparent;
        border: 1px solid #3bea1f;
        color: #000000;
        box-shadow: 0 0 0 1px #3bea1f;
    }

    .btn-primary:active {
        background-color: #27d434;
        border: 1px solid #3bea1f;
        color: #000000;
        box-shadow: 0 0 0 1px #3bea1f;
    }

    .btn-primary:focus {
        background-color: #3bea1f;
        border: 1px solid #3bea1f;
        color: #000000;
        box-shadow: 0 0 0 1px #27d434;
    }
